import React from 'react';
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Graphic from "../components/Graphic";
import Headline from "../components/Headline";
import Navbar from "../components/Navbar";
import TwitterFeed from '../components/TwitterFeed';
import InstagramFeed from '../components/InstagramFeed';
// import EmailSignup from '../components/EmailSignup';

const HomePage = () => {
    return (
        <div>
            <Navbar />
            <Headline />
            <About />
            <TwitterFeed />
            <InstagramFeed />
            <Graphic />
            <Contact />
            {/* <EmailSignup /> */}
            <Footer />
        </div>
    );
}

export default HomePage;